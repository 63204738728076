import React from "react";
import { Box } from "@mui/material";
import "./App.css";
import PngIcon from "./497738.png";
import { isIPad13, isMobile } from "react-device-detect";
import Router from "./Components/Router";

export default function App() {
  const respons = isMobile || isIPad13 ;

  return respons ? (
    <Box className="App">
      <Router/>
    </Box>
  ) : (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          width: "35%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={PngIcon}
          alt="png"
          style={{
            width: "170px",
            margin: "0 auto",
          }}
        />
        <h1
          style={{
            fontSize: "45px",
          }}
        >
          The site does not support for computer !
        </h1>
      </Box>
    </Box>
  );
}
