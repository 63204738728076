import React from "react";
import { Route, Routes } from "react-router-dom";
import Menu from "./Menu";

export default function Router() {
  return (
    <Routes>
      <Route path="/:slug" element={<Menu />} />
    </Routes>
  );
}
