import { Box, Drawer, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import "./menu.css";
import Svganimte1 from "./self-assembling-burger-and-fries-animation1.svg";
import Svganimte2 from "./self-assembling-burger-and-fries-animation2.svg";
import { Icon } from "@iconify/react";
import InlineSVG from "react-inlinesvg";
import ByAliposLogo from './Ресурс 2.svg'
import Pizza from "./Pizza";
import axios from "axios";
import { useParams } from "react-router-dom";
export default function Menu() {
  const [catigoriesobject, setCatigoriesObject] = useState([]);
  const { slug } = useParams();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isDrawerInfoOpen, setDrawerInfoOpen] = useState(false);
  const [isOpenInfo, setOpenInfo] = useState([]);
  const [visibleCategory, setVisibleCategory] = useState(null);
  console.log(slug);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handleDrawerInfoOpen = (item) => {
    setDrawerInfoOpen(true);
    setOpenInfo(item);
  };
  const handleDrawerInfoClose = () => {
    setDrawerInfoOpen(false);
  };
  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY + 1;

    catigoriesobject[0].categories.map((category) => {
      const section = document.getElementById(category.id);
      if (section) {
        const sectionTop = section.offsetTop;
        const sectionBottom = sectionTop + section.offsetHeight;

        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          setVisibleCategory(category.id);
          scrollToActiveBtn();
        }
      }
      return null;
    });
  }, [catigoriesobject, setVisibleCategory]);

  function scrollToActiveBtn() {
    let menuRoot = document.querySelector("#menu-container");
    let activeButton = document.querySelector(".BtnCatigory.active");

    if (activeButton) {
      let parentAnchor = activeButton.closest("a");

      if (parentAnchor) {
        menuRoot.scrollTo({
          left:
            parentAnchor.offsetLeft -
            menuRoot.clientWidth / 2 +
            parentAnchor.clientWidth / 5,
          behavior: "smooth",
        });
      }
    }
  }
  function handleButtonClick(event, type) {
    event.preventDefault();

    const section = document.getElementById(type);
    if (section) {
      const offsetTop = section.offsetTop;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://web.alipos.uz/api/v1/qrmenu/${slug}`
        );
        const categoriesData = [response.data.value];
        const sortedData = categoriesData.map((category) => ({
          organization: category.organization,
          categories: category.categories.map((cat) => ({
            id: cat.id,
            name: cat.name,
            priority: cat.priority,
            products: category.products.filter(
              (product) => product.categoryId === cat.id
            ).sort((a, b) => a.priority - b.priority),
          })).sort((a, b) => a.priority - b.priority),
        }));
        setCatigoriesObject(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        console.error("Error details:", error.response);
      } finally {
        setTimeout(() => {
          setLoading(false);
        },3000);
      }
    };

    fetchData();
  }, [slug]);
  console.log(catigoriesobject);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleShareClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Заголовок вашей страницы",
          text: "Описание вашей страницы",
          url: window.location.href,
        });
        console.log("Успешно поделено!");
      } catch (error) {
        console.error("Ошибка при попытке поделиться:", error);
      }
    } else {
      alert('Ваш браузер не поддерживает функцию "Поделиться".');
    }
  };
  const [loading, setLoading] = useState(true);
  const svgLoading = [Svganimte1, Svganimte2, <Pizza />];
  const randomIndex = Math.floor(Math.random() * svgLoading.length);
  const randomSvg = svgLoading[randomIndex];

  console.log(visibleCategory);
  return loading ? (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#9A27FF",
      }}
    >
      {React.isValidElement(randomSvg) ? (
        <Box>Loading...</Box>
      ) : (
        <Box>
          <Box
            sx={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Loading...
            
          </Box>
        
        </Box>
      )}
        <img src={ByAliposLogo} alt="svg" style={{
              position:'absolute',
              bottom:"10%",
              width:"28%",
              left:"35%",
              right:'35%'

            }} />
    </Box>
  ) : (
    <Box>
      <Box>
        {catigoriesobject.map((item) => (
          <Box
            className="MainLogo"
            sx={{
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "35vh",
                background: "#000",
                opacity: 0.5,
                zIndex: -1,
              }}
            ></Box>
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "35vh",
                backgroundImage: `url(${item.organization.image === 'null' ? './Снимок экрана 2023-12-02 152144.png' : item.organization.image})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                zIndex: -2,
              }}
            ></Box>

            <Box
              key={item}
              sx={{
                zIndex: 2,
              }}
            >
              <h2 id="RestuaranTitle">{item.organization.displayName}</h2>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                padding: "1% 2%",
                zIndex: 2,
              }}
            >
              <Box
                className="ShareBtnt"
                sx={{
                  width: "53px",
                  height: "53px",
                  background: "#fff",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleShareClick}
              >
                <Icon icon="solar:share-outline" fontSize={"30px"} />
              </Box>
            </Box>
          </Box>
        ))}
        <Box className="InfoRestuaran">
          {catigoriesobject.map((item) => (
            <Box key={item}>
              <h2 className="TitleRestuaran">
                {item.organization.displayName}
              </h2>
              <h3 className="DescriptionRestuaran">
                {item.organization.description}
              </h3>
            </Box>
          ))}
        </Box>
        <Box
          className="Menu"
          sx={{
            display: "flex",
            gap: "5vw",
            alignItems: "center",
          }}
        >
          <Box className="CatalogMenu" onClick={handleDrawerOpen}>
            <IconButton>
              <Icon
                icon="ion:menu"
                style={{
                  fontSize: "20px",
                }}
              />
            </IconButton>
          </Box>
          <Box
            id="menu-container"
            sx={{
              overflow: "hidden",
              overflowX: "auto",
              width: "98%",
              paddingRight: "2%",
              display: "flex",
              whiteSpace: "nowrap",
              justifyContent: "flex-start",
              gap: "15px",
              scrollbarWidth: "none",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {catigoriesobject.map((item, index) => (
              <React.Fragment key={index}>
                {item.categories.map((item2, index2) => (
                  <a
                    href={`#${item2.id}`}
                    key={index2}
                    handleButtonClickclassName="LinksBtn"
                  >
                    <button
                      onClick={(e) => handleButtonClick(e, item2.id)}
                      className={`BtnCatigory ${
                        visibleCategory === item2.id ? "active" : ""
                      }`}
                    >
                      <span>{item2.name}</span>
                    </button>
                  </a>
                ))}
              </React.Fragment>
            ))}
          </Box>
        </Box>
        <section className="Section">
          {catigoriesobject.map((item, index) => (
            <React.Fragment key={index}>
              {item.categories.map((category, categoryIndex) => (
                <article
                  className={`articles ${
                    categoryIndex === item.categories.length - 1
                      ? "last-article"
                      : ""
                  }`}
                  id={category.id}
                >
                  <Box
                    sx={{
                      height: "45px",
                    }}
                  ></Box>
                  <h3>{category.name} </h3>
                  <Box
                    sx={{
                      width: "95%",
                      margin: "auto",
                      display: "flex",
                      justifyContent:'flex-start',
                      flexWrap: "wrap",
                      gap:'2%',
                      
                    }}
                    className='BoxCardProduct'
                  >
                    {category.products.map((product, index2) => (
                      <Box
                        key={index2}
                        onClick={() => handleDrawerInfoOpen(product)}
                        sx={{
                          width: "32%",
                          padding: "1.5%  1.5% 7%",
                          borderRadius: "12px",
                          overflow: "hidden",
                          marginBottom: "20px",
                          background: "#f3f3f8",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            minHeight: "220px",
                            overflow: "hidden",
                            borderRadius: "12px",
                            backgroundImage:product.image === null ? 'url(https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg)' : `url(${ product.image})`,
                            backgroundPosition:'center',
                            backgroundRepeat:'no-repeat',
                            backgroundSize:'cover'
                          }}
                        >
                    
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            margin: "auto",
                            padding: "10px 5px",
                            boxSizing: "border-box",
                          }}
                        >
                          <p>{product.name}</p>
                          <p
                            style={{
                              textAlign: "center",
                              width: "90%",
                              margin: "auto",
                              padding: "10px 0",
                              background: "#fff",
                              borderRadius: "12px",
                              position: "relative",
                              fontSize: "14px",
                              boxShadow: " 0 -1px 12px rgba(0,0,0,.08)",
                              top: "15px",
                            }}
                          >{`${product.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} сум`}</p>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </article>
              ))}
            </React.Fragment>
          ))}
        </section>
        <Drawer
          anchor="bottom"
          open={isDrawerInfoOpen}
          onClose={handleDrawerInfoClose}
          className="DrawerOpenInfo"
         
        >
          <Box
            sx={{
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              overflow: "hidden",      
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box className="imgInfoOpen" sx={{
                width:'100%',
                height:'45vh',
                backgroundImage:isOpenInfo.image === null ? `url(https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg)` : `url(${ isOpenInfo.image})`,
                backgroundPosition:'center',
                backgroundRepeat:'no-repeat',
                backgroundSize:'cover'
              }}>
                
              </Box>
              <Box className="OpenInfoText">
                <h3>{isOpenInfo.name}</h3>
                <p>{isOpenInfo.description}</p> <br />
                <p>{`${isOpenInfo.price} сум`}</p>
              </Box>
            </Box>
          </Box>
        </Drawer>
        <Drawer anchor="bottom" open={isDrawerOpen} onClose={handleDrawerClose}>
          <Box
            sx={{
              paddingBottom: "1%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "5px 0",
              }}
              className="drawerOpen"
            >
              <Icon
                icon="mdi:chevron-down"
                onClick={() => setDrawerOpen(false)}
                style={{
                  fontSize: "25px",
                  transform: `rotate(${isDrawerOpen ? "0deg" : "180deg"} )`,
                }}
              />
            </Box>
            <h2
              style={{
                lineHeight: "50px",
                padding: " 1% 2%",
                paddingTop: "3%",
              }}
            >
              Меню
            </h2>
            {catigoriesobject.map((item, index) => (
              <div
                key={item.index}
                className="CatigoryButtons"
                style={{
                  borderBottom: "0.5px solid #ccc",
                  padding: "2%",
                  overflowY: "auto",
                }}
              >
                {item.categories.map((category) => (
                  <a
                    href={`#${category.id}`}
                    onClick={(e) => {
                      handleButtonClick(e, category.id);
                      handleDrawerClose();
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      lineHeight: "40px",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    <span>{category.name}</span>
                    {<p>{category.products.length}</p>}
                  </a>
                ))}
              </div>
            ))}
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
}
