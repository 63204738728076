import React, { useEffect, useRef } from "react";

const Pizza = () => {
  const canvasRef = useRef(null);
  const ctx = useRef(null);
  const sliceCount = useRef(6);
  const sliceSize = useRef(80);
  const width = useRef(0);
  const height = useRef(0);
  const center = useRef(0);
  const sliceDegree = useRef(0);
  const sliceRadians = useRef(0);
  const progress = useRef(0);
  const cooldown = useRef(10);

  useEffect(() => {
    const toRadians = (deg) => (deg * Math.PI) / 180;

    const map = (val, a1, a2, b1, b2) =>
      b1 + ((val - a1) * (b2 - b1)) / (a2 - a1);

    const cheese = (rad, multi, ii) => {
      let x1 =
        sliceSize.current *
        multi *
        Math.cos(toRadians(ii * sliceDegree.current) - 0.2);
      let y1 =
        sliceSize.current *
        multi *
        Math.sin(toRadians(ii * sliceDegree.current) - 0.2);
      let x2 = sliceSize.current * multi * Math.cos(rad + 0.2);
      let y2 = sliceSize.current * multi * Math.sin(rad + 0.2);

      let csx = sliceSize.current * Math.cos(rad);
      let csy = sliceSize.current * Math.sin(rad);

      var d = Math.sqrt((x1 - csx) * (x1 - csx) + (y1 - csy) * (y1 - csy));
      ctx.current.beginPath();
      ctx.current.lineCap = "round";

      let percentage = map(d, 15, 70, 1.2, 0.2);

      let tx = x1 + (x2 - x1) * percentage;
      let ty = y1 + (y2 - y1) * percentage;
      ctx.current.moveTo(x1, y1);
      ctx.current.lineTo(tx, ty);

      tx = x2 + (x1 - x2) * percentage;
      ty = y2 + (y1 - y2) * percentage;
      ctx.current.moveTo(x2, y2);
      ctx.current.lineTo(tx, ty);

      ctx.current.lineWidth = map(d, 0, 100, 20, 2);
      ctx.current.stroke();
    };

    const updatePizza = () => {
      ctx.current.clearRect(0, 0, width.current, height.current);

      if (--cooldown.current < 0)
        progress.current +=
          sliceRadians.current * 0.01 + progress.current * 0.07;

      ctx.current.save();
      ctx.current.translate(center.current, center.current);

      for (let i = sliceCount.current - 1; i > 0; i--) {
        let rad;
        if (i === sliceCount.current - 1) {
          let ii = sliceCount.current - 1;
          rad = sliceRadians.current * i + progress.current;

          ctx.current.strokeStyle = "#FBC02D";
          cheese(rad, 0.9, ii);
          cheese(rad, 0.6, ii);
          cheese(rad, 0.5, ii);
          cheese(rad, 0.3, ii);
        } else rad = sliceRadians.current * i;

        ctx.current.beginPath();
        ctx.current.lineCap = "butt";
        ctx.current.lineWidth = 11;
        ctx.current.arc(
          0,
          0,
          sliceSize.current,
          rad,
          rad + sliceRadians.current
        );
        ctx.current.strokeStyle = "#F57F17";
        ctx.current.stroke();

        let startX = sliceSize.current * Math.cos(rad);
        let startY = sliceSize.current * Math.sin(rad);
        let endX = sliceSize.current * Math.cos(rad + sliceRadians.current);
        let endY = sliceSize.current * Math.sin(rad + sliceRadians.current);
        let variation = [0.9, 0.7, 1.1, 1.2];
        ctx.current.fillStyle = "#FBC02D";
        ctx.current.beginPath();
        ctx.current.moveTo(0, 0);
        ctx.current.lineTo(startX, startY);
        ctx.current.arc(
          0,
          0,
          sliceSize.current,
          rad,
          rad + sliceRadians.current
        );
        ctx.current.lineTo(0, 0);
        ctx.current.closePath();
        ctx.current.fill();
        ctx.current.lineWidth = 0.3;
        ctx.current.stroke();

        let x =
          sliceSize.current * 0.65 * Math.cos(rad + sliceRadians.current / 2);
        let y =
          sliceSize.current * 0.65 * Math.sin(rad + sliceRadians.current / 2);
        ctx.current.beginPath();
        ctx.current.arc(x, y, sliceDegree.current / 6, 0, 2 * Math.PI);
        ctx.current.fillStyle = "#D84315";
        ctx.current.fill();
      }

      ctx.current.restore();

      if (progress.current > sliceRadians.current) {
        ctx.current.translate(center.current, center.current);
        ctx.current.rotate((-sliceDegree.current * Math.PI) / 180);
        ctx.current.translate(-center.current, -center.current);

        progress.current = 0;
        cooldown.current = 20;
      }
    };

    const update = () => {
      requestAnimationFrame(update);
      updatePizza();
    };

    const canvas = canvasRef.current;
    ctx.current = canvas.getContext("2d");

    sliceCount.current = 6;
    sliceSize.current = 80;

    width.current =
      height.current =
      canvas.height =
      canvas.width =
        sliceSize.current * 2 + 50;
    center.current = (height.current / 2) | 0;

    sliceDegree.current = 360 / sliceCount.current;
    sliceRadians.current = toRadians(sliceDegree.current);
    progress.current = 0;
    cooldown.current = 10;

    update();

    return () => cancelAnimationFrame(update);
  }, []);

  return <canvas style={{ width: "25%" }} ref={canvasRef} />;
};

export default Pizza;
